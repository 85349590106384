(function () {
  const Swiper = require("swiper");
  const Choices = require("choices.js");

  const selects = [...document.querySelectorAll(".js-choice")];

  selects.forEach(function (select) {
    if (select) {
      const choices = new Choices(select, {
        searchEnabled: false,
        itemSelectText: "",
      });
    }
  });

  const selectfilter = document.querySelector(".js-choice-filter");

  if (selectfilter) {
    const choicesFilter = new Choices(selectfilter, {
      searchEnabled: true,
      itemSelectText: "",
    });
  }

  function videoDefer() {
    var vidDefer = document.getElementsByTagName("iframe");
    for (var i = 0; i < vidDefer.length; i++) {
      if (vidDefer[i].getAttribute("data-src")) {
        vidDefer[i].setAttribute("src", vidDefer[i].getAttribute("data-src"));
      }
    }
  }

  const aux = document.querySelector(".aux-wrapper");
  const nav = document.querySelector(".header__wrapper");

  const auxHeight = aux.offsetHeight;
  const navHeight = nav.offsetHeight;

  window.addEventListener("scroll", function () {
    const top = window.pageYOffset || document.documentElement.scrollTop;
    if (top > auxHeight) {
      document.body.classList.add("sticky");
      nav.classList.add("sticky");
      aux.classList.add("hidden");
      aux.style.marginBottom = navHeight;
    } else {
      document.body.classList.remove("sticky");
      nav.classList.remove("sticky");
      aux.classList.remove("hidden");
      aux.style.marginBottom = "0";
    }
  });

  const hamburger = document.querySelector(".hamburger");
  if (hamburger) {
    const navMain = document.querySelector(".nav__main");
    const navBackdrop = document.querySelector(".nav__backdrop");
    hamburger.addEventListener("click", function () {
      hamburger.classList.toggle("is-active");
      navMain.classList.toggle("is-active");
      navBackdrop.classList.toggle("is-active");
      document.body.classList.toggle("has-menu");
    });
    // navBackdrop.addEventListener('click', function() {
    //     hamburger.classList.toggle('is-active');
    //     navMain.classList.toggle('is-active');
    //     navBackdrop.classList.toggle('is-active');
    // });

    // const searchButton = document.querySelector('.search__button');
    // const searchContainer = document.querySelector('.search__container');
    // searchButton.addEventListener('click', function() {
    //     searchButton.classList.toggle('is-active');
    //     searchContainer.classList.toggle('is-active');
    // });
  }

  const HomeCarouselContainer = document.querySelector('[data-carousel="home"]');

  if (HomeCarouselContainer) {
    const HomeCarousel = new Swiper(HomeCarouselContainer, {
      speed: 400,
      loop: true,
      watchOverflow: true,
      init: false,
      autoplay: {
        delay: 10000,
      },
      pagination: {
        el: HomeCarouselContainer.querySelector(".swiper-pagination"),
        type: "bullets",
        renderBullet: (index, className) => `<button class="${className}" type="button" aria-title="${index}">
        </button>`,
        clickable: true,
      },
      on: {},
    });

    if (HomeCarouselContainer.querySelectorAll(".swiper-slide").length > 1) {
      HomeCarousel.init();
    } else {
      document.getElementById("brand").style.color = HomeCarouselContainer.querySelector("[data-header-color]").dataset.headerColor;
    }
  }

  const HomeVideos = document.querySelector('[data-carousel="home-video"]');

  if (HomeVideos) {
    const ClientsCarousel = new Swiper(HomeVideos, {
      speed: 400,
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      watchOverflow: true,
      spaceBetween: 60,
      navigation: {
        nextEl: HomeVideos.querySelector(".swiper-button-next"),
        prevEl: HomeVideos.querySelector(".swiper-button-prev"),
      },
    });
  }

  const HomeDepoimentos = document.querySelector('[data-carousel="home-depoimentos"]');

  if (HomeDepoimentos) {
    const ClientsCarousel = new Swiper(HomeDepoimentos, {
      speed: 400,
      autoHeight: true,
      loop: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      watchOverflow: true,
      spaceBetween: 20,
      navigation: {
        nextEl: HomeDepoimentos.querySelector(".swiper-button-next"),
        prevEl: HomeDepoimentos.querySelector(".swiper-button-prev"),
      },
    });
  }

  function initAccordion(accordionElem) {
    function handlePanelClick(event) {
      showPanel(event.currentTarget);
    }

    function showPanel(panelHeader) {
      let isActive,
        panel = panelHeader.parentNode,
        panelBody = panelHeader.nextElementSibling,
        expandedPanel = document.querySelector(".panel.active");

      isActive = expandedPanel && panel.classList.contains("active") ? true : false;

      if (expandedPanel) {
        expandedPanel.querySelector(".acc-body").style.height = null;
        expandedPanel.classList.remove("active");
      }

      if (panel && !isActive) {
        panelBody.style.height = panelBody.scrollHeight + "px";
        panel.classList.add("active");
      }
    }

    let allPanelElements = document.querySelectorAll(".panel");

    for (let i = 0; i < allPanelElements.length; i++) {
      allPanelElements[i].querySelector(".acc-header").addEventListener("click", handlePanelClick);
    }

    showPanel(allPanelElements);
  }

  initAccordion(document.getElementsByClassName("accordion"));

  // Accessibility

  sessionStorage.getItem("contrast") && +sessionStorage.getItem("contrast") && document.body.classList.add("has--high-contrast");

  if (document.querySelector("[data-contrast]")) {
    document.querySelector("[data-contrast]").addEventListener(
      "click",
      () => {
        document.body.classList.toggle("has--high-contrast");

        sessionStorage.setItem("contrast", document.body.classList.contains("has--high-contrast") ? 1 : 0);
      },
      true
    );
  }

  if (sessionStorage.getItem("fontsize")) document.body.style.fontSize = sessionStorage.getItem("fontsize") + "px";

  if (document.querySelector("[data-fontsize-decrease]")) {
    document.querySelector("[data-fontsize-decrease]").addEventListener(
      "click",
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue("font-size"));

        document.body.style.fontSize = --fontsize + "px";

        sessionStorage.setItem("fontsize", fontsize);
      },
      true
    );
  }

  if (document.querySelector("[data-fontsize-increase]")) {
    document.querySelector("[data-fontsize-increase]").addEventListener(
      "click",
      event => {
        event.preventDefault();
        let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue("font-size"));

        document.body.style.fontSize = ++fontsize + "px";

        sessionStorage.setItem("fontsize", fontsize);
      },
      true
    );
  }

  const moreBtn = [...document.querySelectorAll(".more-btn")];
  const moreContent = document.querySelector(".more-content");

  moreBtn.forEach(function (el) {
    if (el) {
      el.addEventListener("click", function (e) {
        e.preventDefault();
        el.classList.toggle("open");
      });
    }
  });

  // document
  //   .getElementById("ico-pasta-de-arquivos-azul-meio-area-logada")
  //   .addEventListener("click", function (e) {
  //     e.preventDefault();
  //     console.log(e.target);
  //   });

  // function cleanPath(thing) {
  //   return thing.replace(/\/$/, "");
  // }

  // ################################################### POE O ACTIVE NO MENU
  const pageHref = window.location.href;

  const mainNavLinks = [...document.querySelectorAll(".nav__item > a")];

  mainNavLinks.forEach(function (el) {
    if (pageHref == el.href) {
      el.parentNode.classList.add("active");
    }
  });

  const GaleriaPost = document.querySelector('[data-carousel="galeria-post"]');

  if (GaleriaPost) {
    const HomeCarousel = new Swiper(GaleriaPost, {
      speed: 400,
      loop: true,
      watchOverflow: true,
      spaceBetween: 60,
      autoplay: {
        delay: 10000,
      },
      navigation: {
        nextEl: GaleriaPost.querySelector(".swiper-button-next"),
        prevEl: GaleriaPost.querySelector(".swiper-button-prev"),
      },
      on: {},
    });
  }

  const aceitoGeralBtn = document.querySelector(".btn-aceito-geral");
  const aceitoGeralBox = document.querySelector(".aceito_geral_box");
  const modalTermos = document.querySelector("[data-modal=termos]");

  aceitoGeralBtn.addEventListener("click", handleAceitoGeral, false);

  function handleAceitoGeral(e) {
    e.preventDefault();
    console.log(e.target);
    Cookies.set("aceito_geral", true);
    setTimeout(function () {
      modalTermos.classList.remove(isVisible);
    }, 500);
  }

  if (aceitoGeralBox) {
    aceitoGeralBox.addEventListener("change", function () {
      if (aceitoGeralBox.checked) {
        Cookies.set("aceito_geral", true);
      } else {
        Cookies.remove("aceito_geral");
      }
    });
  }

  const closeModal = document.querySelectorAll("[data-close]");
  const isVisible = "is-visible";

  window.addEventListener("load", function () {
    if (Cookies.get("aceito_geral")) {
      console.log("Cookie is there!");
      if (aceitoGeralBox) {
        aceitoGeralBox.checked = true;
      }
    } else {
      console.log("No cookie!");
      modalTermos.classList.add(isVisible);
    }
    setTimeout(function () {
      // videoDefer();
      // handleVideoLoader();
      // handleYTApi();
      // handleVideoEvent();
    }, 3000);
  });

  for (const el of closeModal) {
    el.addEventListener("click", function (e) {
      e.preventDefault();
      this.closest(".modal").classList.remove(isVisible);
    });
  }
  document.addEventListener("click", e => {
    if (e.target == document.querySelector(".modal.is-visible")) {
      document.querySelector(".modal.is-visible").classList.remove(isVisible);
    }
  });

  // if (Cookies.get("aceito_geral")) {
  //   aceitoGeralBtn.checked = true;
  //   console.log("Cookie is there!");
  // } else {
  //   console.log("No cookie!");
  // }

  // document.addEventListener("click", function (e) {
  //   console.log(e.target);
  // });

  if (document.querySelector(".duvidas")) {
    const duvidas = document.querySelector(".duvidas");
    const duvidasCloseBtn = duvidas.querySelector(".duvidas__close");

    console.log(duvidas);
    console.log(duvidasCloseBtn);

    duvidasCloseBtn.addEventListener("click", function (e) {
      e.preventDefault();
      duvidas.style.display = "none";
    });
  }

  function handleVideoLoader() {
    const videoLoader = [...document.querySelectorAll(".video-loader")];

    if (videoLoader.length) {
      videoLoader.forEach(function (loader) {
        loader.classList.add("hide");
      });
    }
  }

  function handleVideoEvent() {
    const videoWrapper = [...document.querySelectorAll(".videoWrapper")];

    videoWrapper.forEach(function (el) {
      const video = el.querySelector("iframe[data-src]");
      const playBtn = el.querySelector(".play-btn");

      console.log(el);
      console.log(video);

      playBtn.addEventListener("focus", function (e) {
        console.log(video);
        console.log(e);
        video.play();
      });
    });
  }
})();

var tag = document.createElement("script");
tag.id = "iframe-demo";
tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

function handleYTApi() {
  // console.log("API ready!");

  const videos = [...document.querySelectorAll("iframe[data-src]")];

  videos.forEach(function (video) {
    // console.log(video);

    var player;
    player = new YT.Player(video.id, {
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });

    btn = document.querySelector("#btn-video-" + video.id);
    btn.addEventListener("click", function (e) {
      player.playVideo();
      console.log(e.target.id);
      this.classList.add("hide");
    });
  });
}

function onPlayerReady(event) {
  // console.log("ready!");
}
function onPlayerStateChange(event) {
  // console.log("changed!");
}
